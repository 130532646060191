// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["copHxhhwj"];

const serializationHash = "framer-Jo2lI"

const variantClassNames = {copHxhhwj: "framer-v-bmkgb1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, MZTe_jXJ2: image ?? props.MZTe_jXJ2 ?? {src: "https://framerusercontent.com/images/inXkrvRTdoeYguCLId8ddIHNIUA.png", srcSet: "https://framerusercontent.com/images/inXkrvRTdoeYguCLId8ddIHNIUA.png?scale-down-to=512 512w, https://framerusercontent.com/images/inXkrvRTdoeYguCLId8ddIHNIUA.png 540w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MZTe_jXJ2, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "copHxhhwj", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", intrinsicHeight: 180.5, intrinsicWidth: 270, pixelHeight: 361, pixelWidth: 540, sizes: "min(617px, 100vw)", ...toResponsiveImage(MZTe_jXJ2)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-bmkgb1", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"copHxhhwj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition>
</LayoutGroup>)

});

const css = [".framer-Jo2lI[data-border=\"true\"]::after, .framer-Jo2lI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Jo2lI.framer-vgkacm, .framer-Jo2lI .framer-vgkacm { display: block; }", ".framer-Jo2lI.framer-bmkgb1 { height: 413px; overflow: visible; position: relative; width: 617px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 412.5
 * @framerIntrinsicWidth 617
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"MZTe_jXJ2":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerXUXkkfmp7: React.ComponentType<Props> = withCSS(Component, css, "framer-Jo2lI") as typeof Component;
export default FramerXUXkkfmp7;

FramerXUXkkfmp7.displayName = "Marketplace/Template Card";

FramerXUXkkfmp7.defaultProps = {height: 412.5, width: 617};

addPropertyControls(FramerXUXkkfmp7, {MZTe_jXJ2: {__defaultAssetReference: "data:framer/asset-reference,inXkrvRTdoeYguCLId8ddIHNIUA.png?originalFilename=thumbnail.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerXUXkkfmp7, [])